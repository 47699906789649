import React from "react"
import "./styles/homebliss.css"

const HomeBliss = () => (
  <main>
    <div className="homebliss">
      <h1><img src={ '../../homebliss-logo.svg' } alt="HomeBliss Logo" /> Home Bliss</h1>
      <h2>Home management, all in one place</h2>
      <p>Your source for home information</p>
      <p>Schedule and prioritise home tasks</p>
      <p>Learn how to save money and plan</p>
      <p>Use flexible maintenance schedules</p>
      <p>Stay ahead of your home finances</p> 
      <p>Map your unique home features</p>
      <p>Decide when to tackle home maintenance</p>
      <p>Take on, postpone or dismiss tasks</p>
      <p>Service your home exterior</p>
      <p>Annual schedules with seasonal tips and hints</p>
      <p>Up keep with your garden's annual changes</p>
    </div>
  </main>
)

export default HomeBliss